<template>
    <div class="question-container game-active">        
        <div class="progress" v-if="!true">
            <div class="progress-bar" :style="{width: (progressPercentage*100)+'%'}">Vraag {{ questionProgress }}/{{ game.totalQuestionCount }}</div>
        </div>

        <div class="question-content" v-html="question.content"></div>

        <Question v-if="question.answerCount"></Question>

        <Countdown></Countdown>
    </div>
</template>

<script>
import Question from "../components/Question";
import {mapState, mapGetters} from "vuex";
import Countdown from "@/components/Countdown";

export default {
    name: "Screen",
    components: {
        Countdown,
        Question
    },
    computed: {
        ...mapState(['game', 'question']),
        ...mapGetters(['progressPercentage', 'questionProgress'])
    }
}
</script>

<style scoped>

</style>
