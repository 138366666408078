<template>
    <div class="question">
        <p v-if="question.answerCount == 1" class="intro-title mb-4 text-center text-md-left">Antwoord</p>
        <p v-else class="intro-title mb-4 text-center text-md-left">Antwoorden</p>
        <form method="post" @submit.prevent="submitAnswers">
            <input type="hidden" name="qsq" :value="question.number" />
            <div class="answer-option" v-for="answerIndex in question.answerCount" :key="answerIndex">
                <div class="input-group mb-4"
                     :class="{
                        'is-valid': (answerStates[(answerIndex-1)] === true),
                        'is-invalid': (answerStates[(answerIndex-1)] === false)
                    }"
                >
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ answerIndex }}</span>
                    </div>
                    <input
                        @keypress.enter="onEnterPress"
                        type="text"
                        :name="'answer['+(answerIndex-1)+']'"
                        class="form-control"
                        autocomplete="off"
                        autocapitalize="off"
                        spellcheck="false"
                        autocorrect="off"
                        :placeholder="'Vul hier antwoord ' + answerIndex + ' in'"
                        :disabled="!inputEnabled"
                        :value="getAnswer((answerIndex-1))"
                        @input="setAnswer((answerIndex-1), $event.target.value)"
                    />
                </div>
            </div>
            <div class="alert alert-success text-center font-weight-bold text-uppercase" v-if="!inputEnabled"><i class="far fa-check mr-2"></i> Je antwoord is verzonden</div>
            <div class="buttons d-flex">
                <button type="submit" class="pushy__btn pushy__btn--md pushy__btn--secondary mx-auto mr-md-0" :disabled="!inputEnabled || isSubmitting">
                    Verzenden
                    <i v-if="isSubmitting" class="far fa-sync fa-spin ml-2"></i>
                    <i v-else-if="!inputEnabled" class="far fa-check ml-2"></i>
                    <i v-else class="far fa-paper-plane ml-2"></i>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex';


export default {
    name: "Question",
    computed: {
        inputEnabled () {
            if (this.$store.state.countdownTimer.enabled && this.$store.state.countdownTimer.ended) {
                return false;
            }

            if (this.game.gameType === 'pubquiz') {
                return this.$store.state.submittedAnswers.length === 0;
            }

            return true;
        },
        ...mapState(['game', 'question', 'answers', 'answerStates', 'isSubmitting'])
    },
    methods: {
        getAnswer(answerIndex) {
            return this.$store.getters.getAnswer(answerIndex);
        },
        setAnswer(answerIndex, answer) {
            this.$store.dispatch('setAnswer', { answerIndex, answer });
        },
        submitAnswers() {
            this.$store.dispatch('submitAnswers');
        },
        onEnterPress(e) {
            // Tijdens pubquiz game de enter niet accepteren als submit
            if (this.game.gameType === 'pubquiz') {
                e.preventDefault();
            }
        }
    }
}
</script>

<style scoped>

</style>
