<template>
    <div class="pending-screen pt-5">
        <h1>Welkom bij {{ game.title }}</h1>
        <p class="mb-1">
            Nu is het wachten tot de spelleider het spel start. De verwachtte starttijd is <strong>{{ game.expectedStartTime|luxon('H:mm') }} uur</strong>.
        </p>
        <Loading class="small-loader"></Loading>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Loading from "@/components/Loading";
export default {
    name: "Pending",
    components: {Loading},
    computed: {
        ...mapState(['game'])
    },
}
</script>

<style scoped>

</style>
