<template>
    <div class="countdown-timer" v-if="countdownTimer.enabled">
        <div class="progress" v-if="countdownTimer.enabled && !countdownTimer.ended">
            <div class="progress-bar bg-warning" :style="{width: ((countdownTimer.timeRemaining / countdownTimer.time) * 100) + '%'}"></div>
        </div>
        <div class="progress" v-if="countdownTimer.enabled && countdownTimer.ended">
            <div class="progress-bar bg-danger" style="width:100%"></div>
        </div>
        <div class="title"><i class="far fa-clock mr-3"></i> Nog <strong>{{timeRemaining}}</strong> seconden <i class="far fa-clock alt ml-3"></i></div>        
    </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: "Countdown",
    computed: {
        timeRemaining () {
            if (!this.countdownTimer.enabled) {
                return '';
            }
            //let minutes = Math.floor(this.countdownTimer.timeRemaining / 60);
            let seconds = Math.floor(this.countdownTimer.timeRemaining % 60);
            //return minutes + ':'+((seconds < 10) ? '0' : '')+seconds;
            return seconds;
        },
        ...mapState(['countdownTimer'])
    }
}
</script>

<style scoped>

</style>
<!-- 

    

    -->