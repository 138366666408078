<template>
    <div class="message">
        <div class="alert alert-default">
            <p>De live-verbinding met andere spelers is op dit moment offline.</p>
        </div>
    </div>
</template>

<script>
export default {
name: "WebsocketError"
}
</script>

<style scoped>

</style>
