<template>
    <div class="row game-finished text-center flex-column">
        <p class="intro-title">Het spel is afgelopen<br /><br />Bedankt voor het meespelen!</p>
    </div>
</template>

<script>
export default {
name: "Finished"
}
</script>

<style scoped>

</style>
