<template>
    <div class="game-view" v-bind:class="{ 'show-logo' : !isLoading && game.status === 'finished' }">
        <div class="text-center position-relative logo" v-if="!isLoading && (game.status === 'finished')">
            <img src="/img/logo-quizut-2020.png" alt="QuizUt" />
        </div>
        <Loading v-if="isLoading" class="game-loader"></Loading>        
        <div class="game" v-else>
            <div class="frame">
                <span class="left-top"></span>
                <span class="top"></span>
                <span class="right-top"></span>
                <span class="right"></span>
                <span class="right-bottom"></span>
                <span class="bottom"></span>
                <span class="left-bottom"></span>
                <span class="left"></span>
            </div>
            <div class="game-content">
                <Pending v-if="game.status === 'pending'"></Pending>
                <Finished v-if="game.status === 'finished'"></Finished>
                <Completed v-if="team.isCompleted && game.status === 'active'"></Completed>
                <Screen v-if="!team.isCompleted && game.status === 'active'"></Screen>
            </div>
        </div>
        <WebsocketError v-if="hasWebsocketError"></WebsocketError>
    </div>
</template>

<script>
import Pending from "../components/Pending";
import Finished from "../components/Finished";
import Completed from "../components/Completed";

import { mapState } from 'vuex';
import WebsocketError from "@/components/WebsocketError";
import Loading from "@/components/Loading";
import Screen from "@/components/Screen";

export default {
name: "Game",
    components: {Screen, Loading, WebsocketError, Completed, Finished, Pending},
    mounted() {
        this.loadGame(this.$route.params.token);
    },
    data: () => ({
        isLoading: true
    }),
    computed: {
        ...mapState(['game', 'team', 'hasWebsocketError'])
    },
    watch: {
        '$route.params.token' () {
            this.loadGame(this.$route.params.token);
        }
    },
    methods: {
        loadGame(token) {
            this.isLoading = true;
            setTimeout(function(){
                this.$store.dispatch('initializeGame', {token: token})
                .then(() => {
                    this.isLoading = false;
                });
            }.bind(this), 500);

        }
    }
}
</script>

<style scoped>

</style>
