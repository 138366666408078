<template>
    <div class="row game-finished text-center flex-column">
        <p class="intro-title">Julie team is klaar!<br /><br />Wacht op de spelleider</p>
    </div>
</template>

<script>
export default {
    name: "Completed"
}
</script>

<style scoped>

</style>
